import React from 'react';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        marginLeft: 20,
    },
    avatar: {
        margin: theme.spacing(1),
      },

    logout: {
        marginLeft: theme.spacing(5),
    }
}));

export default function NavBar(props) {
    // console.log(props)
    // console.log(props.currentPage)
    // console.log(props.currentPage !== 'home')

    // const currentPage = props.currentPage

    // const [home, setHome] = React.useState(true);
    // const [myData, setMyData] = React.useState(false);
    // const [photos, setPhotos] = React.useState(false);
    // const [dashboard, setDashboard] = React.useState(false);

    // React.useEffect(
    //     setCurrentPage()
    // )

    // const setCurrentPage  = () => {
    //     switch (currentPage) {
    //         case 'home':
    //             console.log(currentPage)
    //             setHome(true)
    //             setMyData(false)
    //             setPhotos(false)
    //             setDashboard(false)
    //             break;
    //         case 'myData':
    //             console.log(currentPage)
    //             setHome(false)
    //             setMyData(true)
    //             setPhotos(false)
    //             setDashboard(false)
    //             break;
    //         case 'photos':
    //             console.log(currentPage)
    //             setHome(false)
    //             setMyData(false)
    //             setPhotos(true)
    //             setDashboard(false)
    //             break;
    //         case 'dashboard':
    //             console.log(currentPage)
    //             setHome(false)
    //             setMyData(false)
    //             setPhotos(false)
    //             setDashboard(true)
    //             break;
    //     }
    // }


    const history = useHistory();

    const API_URL_LOGOUT = "api/logout";
    const API_IS_ADMIN = "api/is_admin"

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [adminChecked, setAdminChecked] = React.useState(false)
    const [isAdmin, setIsAdmin] = React.useState(false)

    React.useEffect( () => {
        // without if statement this gets called infinitely
        if (!adminChecked) {
          console.log('use Effect')
          
          getAdminPermission().then( 
            response => setIsAdmin(response?.is_admin) )
          .then( () => setAdminChecked(true))
        }
    })


    async function getAdminPermission() {
        console.log('starting getLeaders', API_IS_ADMIN)
        try {
              const response = await fetch(API_IS_ADMIN, {
                      method: 'GET',
                      headers: new Headers({
                          'Authorization': "Bearer " + localStorage.access_token, 
                          // 'Content-Type': 'application/json'
                        })
                  })
              try {
                console.log(response)
                let res = await response.json()
              // console.log(await response.text());
              console.log(res);
              if (!res.hasOwnProperty("msg")) {
      
                // Execute callback function which sets the parameters from the result file
                if (res !== null) {
                    console.log('result received from server', res)
                    return res
                    
                }
            } else {
                // Session timed out --> Login again
                history.push("/out/true");
                // console.log('should maybe be logged out')
            }
              }
              catch (err) {
                console.log('response.json failed')
                console.log(err)
              }
            
          }
            catch (err) {
              console.log('fetch failed', err);
              // history.push("/out/true");
          }
        }


    const onClick = (event) => {
        console.log(event.currentTarget);
        const id = event.currentTarget.id;
        switch (id) {
            case "menu":
                setAnchorEl(event.currentTarget);
                break;
            case "home":
                handleClose();
                history.push("/");
                break;
            case "myData":
                handleClose();
                history.push("/data");
                break;
            case "dashboard":
                handleClose();
                history.push("/dashboard")
                break;
            case "fotos":
                handleClose();
                history.push("/photos")
                break
            case "projects":
                handleClose();
                history.push("/projects")
                break
            case "admin":
                handleClose();
                history.push("/admin")
                break
            
            case "logout":
                // Send HTTP POST request to delete refresh token
                fetch(API_URL_LOGOUT, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Authorization': "Bearer " + localStorage.refresh_token, 
                        'Content-Type': 'application/json'
                    }), 
                })
                    .then(res => res.json())
                    .then(
                        result => {
                            localStorage.removeItem('access_token');
                            localStorage.removeItem('refresh_token');
                            history.push('/login')

                            console.log("Refresh token deleted")
                        },
                        (error) => {
                            console.log(error);
                        }
                    )
                break;
            default:
                throw new Error('Unknown button id');
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
      };

    const classes = useStyles();

    return (
        <React.Fragment>
            <AppBar position="relative" color="primary">
                <Toolbar>
                <IconButton id="menu" onClick={onClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >      
                    <MenuItem 
                    id="home"
                    onClick={onClick}
                    color="secundary"
                    >
                    Neuer Eintrag
                    </MenuItem> 
                    <MenuItem 
                            id="myData"
                            onClick={onClick}
                            color="secundary"
                    >
                            Meine Daten
                    </MenuItem>
                    <MenuItem 
                            id="dashboard"
                            onClick={onClick}
                            color="secundary"
                    >
                            Dashboard
                    </MenuItem>
                    <MenuItem 
                            id="fotos"
                            onClick={onClick}
                            color="secundary"
                    >
                            Alle Fotos
                    </MenuItem>
                    <MenuItem 
                            id="projects"
                            onClick={onClick}
                            color="secundary"
                    >
                            Projekte
                    </MenuItem>
                    { isAdmin && 
                        <MenuItem 
                        id="admin"
                        onClick={onClick}
                        color="secundary"
                        >
                                Admin
                        </MenuItem>
                    }
                </Menu>


                <Typography className={classes.title}>
                Mobil zum Spenden
                </Typography>
                            <Button
                                id="logout"
                                onClick={onClick}
                                variant="outlined"
                                className={classes.logout}
                            >
                                Logout
                            </Button>

                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}