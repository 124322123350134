import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@material-ui/styles';

import { useHistory } from "react-router-dom";
import Copyright from './Copyright';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [registerKey, setRegisterKey] = React.useState("");
  const [fNameError, setFNameError] = React.useState(false)
  const [lNameError, setLNameError] = React.useState(false)
  const [emailError, setEmailError] = React.useState(false)
  const [emailTakenError, setEmailTakenError] = React.useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("")
  const [passwordError, setPasswordError] = React.useState(false);

  const [registerKeyEmptyError, setRegisterKeyEmptyError] = React.useState(false);
  const [registerKeyError, setRegisterKeyError] = React.useState(false);
  const [registerKeyErrorMessage, setRegisterKeyErrorMessage] = React.useState("")
  
  
  
  const [password2Error, setPassword2Error] = React.useState(false);




  const handleChange = (event) => {
    console.log(event);
    const id = event.target.id;
    console.log("Following ID triggered change handling: " + event.target.id);
    switch (id) {
        case "registerKey":
          setRegisterKey(event.target.value);
          resetErrors();
          break;
        case "firstName":
            setFirstName(event.target.value);
            resetErrors();
            break;
        case "lastName":
            setLastName(event.target.value);
            resetErrors();
            break;
        case "email":
            setEmail(event.target.value);
            resetErrors();
            break;
        case "password":
            setPassword(event.target.value);
            resetErrors();
            break;
        case "password2":
          setPassword2(event.target.value)
          resetErrors();
          break;
        default:
            throw new Error('Unknown step');
    }
};

const resetErrors = () => {
  setPasswordError(false)
  setFNameError(false);
  setLNameError(false);
  setEmailError(false);
  setPassword2Error(false);
  setRegisterKeyEmptyError(false)
}


  const onClick = (event) => {
    console.log(event.currentTarget);
    // const id = event.currentTarget.id;

    
    let error = false

    if (password !== password2){
      setPassword2Error(true)
      setPassword('')
      setPassword2('')
      error = true
    }

    if (password === "") {
      setPasswordError(true);
      error = true
    } 
    if (firstName === "") {
      setFNameError(true);
      error = true
    }
    if (lastName === "") {
      setLNameError(true);
      error = true
    }
    if (email === "") {
      setEmailError(true);
      // emailAlert()
      setEmailErrorMessage("E-Mail darf nicht leer sein")
      console.log(emailErrorMessage)
      error = true
    } 
    if (registerKey === '') {
      setRegisterKeyErrorMessage('Registrierungschlüssel darf nicht leer sein')
      setRegisterKeyEmptyError(true)
      error = true
    }

    if (!error) {
      signupRequest();  
    }
  }


  const signupRequest = () => {
    const convertedState = {
      register_key: registerKey,
      first_name: firstName,
	    last_name: lastName,
	    email: email,
	    password: password
    };
    // Send HTTP POST request
    const signupAPI = "api/register"
    console.log("signupRequest")
    return fetch(signupAPI, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(convertedState)
    })
        .then(res => res.json())
        .then(
            result => {
                console.log("Result received from server: ", result); 

                if (result !== undefined) {

                    if (!result.hasOwnProperty("error")) {
                        // Go to login
                        history.push("/login");

                    } else {
                        //Email already used
                        console.log(result.value)
                        if (result.value == '1') {
                          setEmailError(true)
                          setEmailErrorMessage("E-Mail Adresse schon belegt") 
                        } else {
                          setRegisterKeyError(true);
                          setRegisterKeyErrorMessage("Falscher Rsgistrierungscode")
                          
                        }
                        
                        // setPasswordError(true);
                    }
                    
                }
            },
            (error) => {
                console.log(error);
            }
        )
  }

  return (
    <ThemeProvider theme={props.theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
              <TextField
                required
                // autoComplete="fname"
                name="registerKey"
                variant="outlined"
                fullWidth
                id="registerKey"
                label="Registrierungscode"
                value={registerKey}
                onChange={handleChange}
                autoFocus
                className={classes.field}
                error={registerKeyError}
                // helperText={registerKeyError ? "Registrierungscode darf nicht leer sein" : ""}
                helperText={emailErrorMessage}
                inputProps={{
                    style: { textAlign: "left" }
                  }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                fullWidth
                id="firstName"
                label="Vorname"
                value={firstName}
                onChange={handleChange}
                // autoFocus
                className={classes.field}
                error={fNameError}
                helperText={fNameError ? "Vorname darf nicht leer sein" : ""}
                inputProps={{
                    style: { textAlign: "left" }
                  }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                fullWidth
                id="lastName"
                label="Nachname"
                name="lastName"
                value={lastName}
                onChange={handleChange}
                autoComplete="lname"
                className={classes.field}
                error={lNameError}
                helperText={lNameError ? "Nachname darf nicht leer sein" : ""}
                inputProps={{
                    style: { textAlign: "left" }
                  }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email / Benutzername"
                name="email"
                autoComplete="email"
                value={email}
                onChange={handleChange}
                error={emailError}
                helperText={emailErrorMessage}
                className={classes.field}
                inputProps={{
                    style: { textAlign: "left" }
                  }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Passwort"
                type="password"
                id="password"
                // autoComplete="current-password"
                value={password}
                onChange={handleChange}
                error={passwordError}
                helperText={passwordError ? "Passwort darf nicht leer sein" : ""}
                className={classes.field}
                inputProps={{
                    style: { textAlign: "left" }
                  }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password2"
                label="Passwort wiederholen"
                type="password"
                id="password2"
                // autoComplete="current-password"
                value={password2}
                onChange={handleChange}
                error={password2Error}
                helperText={password2Error ? "Passwörter stimmen nicht überein" : ""}
                className={classes.field}
                inputProps={{
                    style: { textAlign: "left" }
                  }}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            id="confirm"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onClick}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Typography onClick={()=>{history.push("/login")}} id="login" variant="body1" color="primary" >
                {"Sie haben bereits ein Konto? Sign in"}
              </Typography> 
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
    </ThemeProvider>
  );
}