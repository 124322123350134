import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from '@material-ui/styles';
import {makeStyles} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";

// local imports
import NavBar from "./NavBar";
import Copyright from './Copyright';

// Toggle
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { FaRunning } from 'react-icons/fa';
import { MdPhotoSizeSelectActual } from 'react-icons/md';

// Splitup
import MyEntries from './MyEntries'
import MyPhotos from './MyPhotos'

const useStyles = makeStyles(theme => ({
    logo: {
        margin: theme.spacing(3),
        // backgroundColor: theme.palette.secondary.main,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        // backgroundColor: "rgba(255, 255, 255, 0.9)",
        backgroundColor: "white",
    },
    card: {
        minWidth: 275,
        background: 'linear-gradient(45deg, #f7f7f7 30%, #ffffff 90%)',
    },
    title: {
        marginBottom: 15,
    },
    date: {
        // marginTop: 5,
        fontSize: 14,
    },
    material: {
        marginTop: 12,
    },
    subMaterial: {
        marginTop: 0,
    },
    projectId: {
        marginTop: 0,
    },
    projectName: {
        marginTop: 5,
    },
    root: (props) => {
        return {
          // color: theme.palette[props.color].main
          color: '#F7A81B'
          // some other custom styles
        };
      },
}));

function ColoredToggleButtons(props) {
    const { color, ...other } = props;
    const classes = useStyles({ color });
    return <ToggleButton classes={classes} {...other} />;
  }

export default function MyData(props) {
    const classes = useStyles();
    // const history = useHistory();

    const [entriesSelected, setEntrieselected] = React.useState(true);
    const [alignment, setAlignment] = React.useState('entries');

    const handleChange = (event, id) => {
        console.log(id)
        setAlignment(id);
        switch (id) {
            case "entries":
                setEntrieselected(true);
                break;
            case "photos":
                setEntrieselected(false);
                break;
        }
      };


    return (
        <ThemeProvider theme={props.theme}>
        <React.Fragment>
            <CssBaseline/>
            <NavBar
                // host={props.host}
                currentPage='myData'
            />
            <main className={classes.layout}>
                < Box my={5}>
                    <Grid container spacing={2} direction="column" alignItems="center">
                        <Grid item>
                            <ToggleButtonGroup size="large" value={alignment} exclusive onChange={handleChange}>
                                <ColoredToggleButtons value="entries">
                                    <FaRunning size={30} />
                                </ColoredToggleButtons>
                                <ColoredToggleButtons value="photos">
                                    <MdPhotoSizeSelectActual size={30} />
                                </ColoredToggleButtons>
                            </ToggleButtonGroup>
                    </Grid>
                </Grid>
                </Box>
                {entriesSelected ? < MyEntries 
                    // host={props.host}
                    theme={props.theme}
                />
            :  < MyPhotos 
                    // host={props.host}
                    theme={props.theme}
                />}

                        <Copyright 
                        style={
                            {
                            flex: 1,
                            justifyContent: 'flex-end',
                            marginBottom: 36
                        }}
                        />
            </main>
        </React.Fragment>
    </ThemeProvider>
    );
};