import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import { FaHiking , FaBiking, FaRunning, FaHome, FaDatabase }  from 'react-icons/fa';
import { ImSigma }  from 'react-icons/im';
import useForceUpdate from '../ForceUpdate'

// adminComponent imports
// import TotalTable from './TotalTable';
// import AllEntries from './AdminTable';
import AdminTable from './AdminTable';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function SideBar(props) {
    const history = useHistory();
    const { window } = props;
    const data = props.data
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [selectedType, setSelectedType] = React.useState(<ImSigma/>)
    const [totalsList, setTotalslist] = React.useState(data.totals_list['total'])
    const [allEntriesData, setAllEntriesData] = React.useState(data.all_entries)
    const [selectedMenu, setSelectedMenu] = React.useState('totals')
    const [tableTitle, setTableTitle] = React.useState('Total Insgesammt')
    const [tableData, setTableData] = React.useState(totalsList)
    const forceUpdate = useForceUpdate();
    


    const [columns, setColumns] = React.useState([
      { field: 'name', headerName: 'Name', title: 'Name' },
      { field: 'distance', headerName: 'Distanz', title: 'Distanz', defaultSort: 'desc'},
      { field: 'chf', headerName: 'CHF', title: 'CHF'},
  ])

    
    
    

    const iconList = [<FaHome/>, <ImSigma/>, <FaHiking/>, <FaBiking/>, <FaRunning/>]
    const nameList = ['home', 'total', 'hiking', 'biking', 'jogging']

    const iconDic = {
        'total': <ImSigma/>,
        'hiking': <FaHiking/>,
        'biking': <FaBiking/>,
        'jogging': <FaRunning/>,
    }

    const titleDic = {
      'total': 'Total Insgesamt',
      'hiking': 'Total Wandern',
      'biking': 'Total Fahrad',
      'jogging': 'Total Joggen',
  }
    
    
    

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const onClick = (event) => {
        console.log(event.currentTarget)
        const buttonId = event.currentTarget.id;
        // const avatar = parseInt(event.currentTarget.avatar);
        console.log({buttonId})
        if (buttonId==="home") {
            history.push('/');
        } else if (buttonId==='allEntries') {
          setTableTitle('Alle Einträge')
          setColumns([
            { field: 'name', headerName: 'Name', title: 'Name'},
            { field: 'date', headerName: 'Datum', title: 'Datum', type: 'date'},
            { field: 'type', headerName: 'Art', title: 'Art'},
            { field: 'distance', headerName: 'Distanz', title: 'Distanz'},
            { field: 'chf', headerName: 'CHF', title: 'CHF'},
        ])
        setTableData(allEntriesData)
        forceUpdate();
          // handleSelected('allEntries')
          console.log(tableTitle)
            // setSelectedMenu('allEntries')
        } else {
            setTotalslist(data.totals_list[buttonId])
            // setSelectedType(iconDic[buttonId])
            setTableTitle(titleDic[buttonId])
            // setSelectedMenu('totals')
            setColumns([
              { field: 'name', headerName: 'Name', title: 'Name' },
              { field: 'distance', headerName: 'Distanz', title: 'Distanz', defaultSort: 'desc'},
              { field: 'chf', headerName: 'CHF', title: 'CHF'},
          ])
     
            setTableData(totalsList)
            forceUpdate();
            // handleSelected('totals')
            console.log(tableTitle)
        };
        
    };

    // const handleSelected = (selected) => {
    //   setSelectedMenu(selected)
    //   if (selected==='totals') {
    //     setColumns([
    //       { field: 'name', headerName: 'Name', title: 'Name' },
    //       { field: 'distance', headerName: 'Distanz', title: 'Distanz', defaultSort: 'desc'},
    //       { field: 'chf', headerName: 'CHF', title: 'CHF'},
    //   ])
 
    //     setTableData(totalsList)
    //   } else {
    //     setColumns([
    //       { field: 'name', headerName: 'Name', title: 'Name'},
    //       { field: 'date', headerName: 'Datum', title: 'Datum', type: 'date'},
    //       { field: 'type', headerName: 'Art', title: 'Art'},
    //       { field: 'distance', headerName: 'Distanz', title: 'Distanz'},
    //       { field: 'chf', headerName: 'CHF', title: 'CHF'},
    //   ])
    //   setTableData(allEntriesData)
    //   }

    // }



    // return < TotalTable data={totalsList} theme={theme} avatar={selectedType} />
    // const RenderSelected = (selected) => {
    //     console.log('RenderSelected: ', selected)
    //     let columns = []
    //     let tableData = []
    //     switch (selected) {
    //       case 'totals':
    //           data=totalsList
    //           columns=[
    //               { field: 'name', headerName: 'Name', title: 'Name' },
    //               { field: 'distance', headerName: 'Distanz', title: 'Distanz', defaultSort: 'desc'},
    //               { field: 'chf', headerName: 'CHF', title: 'CHF'},
    //           ]
    //           break;
    //       case 'allEntries':
    //           tableData=allEntriesData
    //           columns = [
    //               { field: 'name', headerName: 'Name', title: 'Name'},
    //               { field: 'date', headerName: 'Datum', title: 'Datum', type: 'date'},
    //               { field: 'type', headerName: 'Art', title: 'Art'},
    //               { field: 'distance', headerName: 'Distanz', title: 'Distanz'},
    //               { field: 'chf', headerName: 'CHF', title: 'CHF'},
    //           ]
    //         break;
                   
    //     }
    //     return < AdminTable data={tableData} columns={columns}
    //   }
          // switch (selected) {
          //   case 'totals':
          //       return < AdminTable 
          //                 data={totalsList} 
          //                 columns={
          //                     [
          //                     { field: 'name', headerName: 'Name', title: 'Name' },
          //                     { field: 'distance', headerName: 'Distanz', title: 'Distanz', defaultSort: 'desc'},
          //                     { field: 'chf', headerName: 'CHF', title: 'CHF'},
          //                     ]
          //                 }
          //               /> 
          //   case 'allEntries':
          //       return < AdminTable 
          //                 data={allEntriesData} 
          //                 columns={
          //                   [
          //                     { field: 'name', headerName: 'Name', title: 'Name'},
          //                     { field: 'date', headerName: 'Datum', title: 'Datum', type: 'date'},
          //                     { field: 'type', headerName: 'Art', title: 'Art'},
          //                     { field: 'distance', headerName: 'Distanz', title: 'Distanz'},
          //                     { field: 'chf', headerName: 'CHF', title: 'CHF'},
          //                   ]
          //                 }
          //               /> 
          // }
          
        

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {['Home', 'Summe', 'Wandern', 'Fahrrad', 'Jogging'].map((text, index) => (
          <ListItem button key={text} id={nameList[index]} avatar={iconList[index]} onClick={onClick} >
            <ListItemIcon>
                {iconList[index]}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <ListItem button key='allEntries' id="allEntries" onClick={onClick} >
            <ListItemIcon>
            < FaDatabase />
            </ListItemIcon>
            <ListItemText primary="Alle Einträge" />
          </ListItem>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/* < TotalTable data={totalsList} theme={theme} avatar={selectedType} /> */}
        {/* {RenderSelected(selectedMenu)} */}
        < AdminTable title={tableTitle} data={tableData} columns={columns} />
      </main>
    </div>
  );
}


