import * as React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";

// local imports
import Copyright from './Copyright';
import LoadingTracker from './LoadingTracker';


// admin imports
import SideBar from './adminComponents/SideBar';


const useStyles = makeStyles(theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        background: "white",
        // minWidth: 320
  
    },
    root: (props) => {
      return {
        // color: theme.palette[props.color].main
        color: '#F7A81B'
        // some other custom styles
      };
    },
  }));

export default function Admin(props) {
    const history = useHistory();
    const classes = useStyles();

    const API_DATA = 'api/data'

    // const [dataFetched, setDataFetched] = React.useState(false)
    const [data, setData] = React.useState()


    React.useEffect( () => {
        console.log('useEffect Admin')
        if (!data) {
            getData()
            // .then( res => setData(res.data))
        }
    })

  async function getData() {
    console.log('starting getData', API_DATA)
    try {
          const response = await fetch(API_DATA, {
                  method: 'GET',
                  headers: new Headers({
                      'Authorization': "Bearer " + localStorage.access_token, 
                      // 'Content-Type': 'application/json'
                    })
              })
          try {
            console.log(response)
            let res = await response.json()
          // console.log(await response.text());
          console.log(res);
          if (!res.hasOwnProperty("msg")) {
  
            // Execute callback function which sets the parameters from the result file
            if (res !== null) {
                console.log('result received from server', res)
                console.log({res})
                if (res.hasOwnProperty('data')) {
                    setData(res.data)
                }
                // return res
                
            }
        } else {
            // Session timed out --> Login again
            history.push("/out/true");
            // console.log('should maybe be logged out')
        }
          }
          catch (err) {
            console.log('response.json failed')
            console.log(err)
          }
        
      }
        catch (err) {
          console.log('fetch failed', err);
          // history.push("/out/true");
      }
    }

  return (
    <ThemeProvider theme={props.theme}>
    <React.Fragment>
    <CssBaseline/>
    <main className={classes.layout}>
    { data ? 
    < SideBar data={data} />
    : < LoadingTracker />}
      <Box mt={2}>
      <Copyright 
      style={
          {
          flex: 1,
          justifyContent: 'flex-end',
          marginBottom: 36
      }}
      />
      </Box>
      </main>
    </React.Fragment>
  </ThemeProvider>
  );
}