import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    maxWidth: 300,
  },
});

export default function TotalCardNew(props) {
    const classes = useStyles();

    const typeAvatar = props.typeLogo
    const totalKm = props.totalKm
    const totalCHF = props.totalCHF

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Total  &emsp; {typeAvatar}
          </Typography>

        </CardContent>
      </CardActionArea>
      <CardActions>
          {totalKm ? 
          <Button size="large" color="primary" align="left" >
          {totalKm} Km
        </Button>
          : ""}
        <Button size="large" color="primary" align="right" >
          {totalCHF} CHF
        </Button>
      </CardActions>
    </Card>
  );
}

{/* <Box component="div" display="inline">inline</Box>
<Box component="div" display="inline">inline</Box> */}