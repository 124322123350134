import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CssBaseline from "@material-ui/core/CssBaseline";


import Copyright from './Copyright';
import NavBar from './NavBar'
import Total from './dashboardComponents/Total'
import Leaderbord from './dashboardComponents/Leaderboard'
// import CardExample from './dashboardComponents/CardExample'


// Toggle
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { FaHiking , FaBiking, FaRunning }  from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
      },
  },
  paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
      },
      background: "white",
      // minWidth: 320

  },
  root: (props) => {
    return {
      // color: theme.palette[props.color].main
      color: '#F7A81B'
      // some other custom styles
    };
  },
}));


// const useStyles = makeStyles((theme) => ({
//   root: (props) => {
//     return {
//       // color: theme.palette[props.color].main
//       color: '#F7A81B'
//       // some other custom styles
//     };
//   }
// }));

function ColoredToggleButtons(props) {
  const { color, ...other } = props;
  const classes = useStyles({ color });
  return <ToggleButton classes={classes} {...other} />;
}

export default function Dashboard(props) {
  const classes = useStyles();
  const history = useHistory();

  const API_LEADERS = 'api/leaders'

  console.log('starting Dashboard')

  const [alignment, setAlignment] = React.useState('hiking');
  const [leadersFetched, setLeadersFetched] = React.useState(false);
  const [leaders, setLeaders] = React.useState({'hiking': [], 'biking': [], 'jogging': []})



  const handleAlignment = (event, newAlignment) => {
    if (newAlignment!==null){
      setAlignment(newAlignment);
    }
  };

  React.useEffect( () => {
    console.log('useEffect Dashboard')
    if (!leadersFetched) {
        getLeaders();
        setLeadersFetched(true) ;
        console.log('leadersFetched', leadersFetched)
    }
})

const RenderTypes = (type) => {
  console.log('RenderTypes with alignment: ', alignment)
    switch (type) {
      case 'hiking':
          return < Leaderbord type="hiking" data={leaders.hiking}/>

      case 'biking':
          return < Leaderbord type="biking" data={leaders.biking}/>

      case 'jogging':
          return < Leaderbord type="jogging" data={leaders.jogging}/>

    }
  }



function formatList(l) {
  console.log('formatList', l)
  var formattedList = []
  l.forEach(function (user) {
      const name = user.name
      const total = user.total
      formattedList.push({name, total})
      // formattedList.push({name, total})
      
  })
  console.log(formatList)
  return formattedList
  // .then( () => setTableList(formattedList))
  // console.log('formatted list', formatList)
  // console.log('table list after update', tableList)
}

function formatDict(d){
  console.log(d)
  var formattedDic = d
  // var formattedDic = {}
  // for (const [key, value] of Object.entries(d)) {
  //   console.log(key, value);
  //   formattedDic[key] = formatList(value)
  // }
  setLeaders(formattedDic)
  console.log('leaders', leaders)
}

  async function getLeaders() {
    console.log('starting getLeaders', API_LEADERS)
    try {
          const response = await fetch(API_LEADERS, {
                  method: 'GET',
                  headers: new Headers({
                      'Authorization': "Bearer " + localStorage.access_token, 
                      // 'Content-Type': 'application/json'
                    })
              })
          try {
            console.log(response)
            let res = await response.json()
          // console.log(await response.text());
          console.log(res);
          if (!res.hasOwnProperty("msg")) {
  
            // Execute callback function which sets the parameters from the result file
            if (res !== null) {
                console.log('result received from server', res)
                formatDict(res.leaders)
                
            }
        } else {
            // Session timed out --> Login again
            history.push("/out/true");
            // console.log('should maybe be logged out')
        }
          }
          catch (err) {
            console.log('response.json failed')
            console.log(err)
          }
        
      }
        catch (err) {
          console.log('fetch failed', err);
          // history.push("/out/true");
      }
    }

    return (
      <ThemeProvider theme={props.theme}>
        <React.Fragment>
        <CssBaseline/>
        < NavBar theme={props.theme} currentPage='dashboard'/>
        <main className={classes.layout}>
            <Paper  className={classes.paper}
                    elevation10
            >
              <Box my={2} >
                <Typography variant="h4" align="center">
                  Meine Leistungen
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={1} direction="column" alignItems="center" size='large'> 
                  <Total theme={props.theme} align='center'/>
                </Grid>
              </Box>       
            </Paper>
            <Paper  
                    elevation10
                    classname={classes.paper}
            >
           <Box visibility="hidden">
              {/* <Box style={{ padding: 4 }}> */}
                <Typography variant="h8" align="center" >
                  .
                </Typography>
              </Box>
              <Box my={2}>
              {/* <Box style={{ padding: 4 }}> */}
                <Typography variant="h4" align="center">
                  Leaderbord
                </Typography>
              </Box>
              < Box my={2} >  
                <Grid container spacing={1} direction="column" alignItems="center" size='large'> 
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    size='large'
                  >
                    <ColoredToggleButtons value="hiking" aria-label="left aligned"  >
                      <FaHiking />
                    </ColoredToggleButtons>
                    <ColoredToggleButtons value="biking" aria-label="centered">
                      <FaBiking />
                    </ColoredToggleButtons>
                    <ColoredToggleButtons value="jogging" aria-label="right aligned">
                      <FaRunning />
                    </ColoredToggleButtons>
                  </ToggleButtonGroup>  
                </Grid>
              </Box>
              <Grid container spacing={2} direction="column" alignItems="center">
                < Box my={2} >    
                  {RenderTypes(alignment)}
                </Box>
              </Grid>
            </Paper>
          <Box mt={2}>
          <Copyright 
          style={
              {
              flex: 1,
              justifyContent: 'flex-end',
              marginBottom: 36
          }}
          />
          </Box>
          </main>
        </React.Fragment>
      </ThemeProvider>
    );
  }




