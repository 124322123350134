import * as React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";


// icons
import { FaHiking , FaBiking, FaRunning }  from 'react-icons/fa';

// local imports
import TotalCard from './TotalCard';



const useStyles = makeStyles(theme => ({
  layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
      },
  },
  paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
      },
      background: "white",
      // width: 318
      minWidth: 320
  },
card: {
    // width: 350,
    // minWidth: 300,
    background: 'linear-gradient(45deg, #f7f7f7 30%, #ffffff 90%)',
},
}));




export default function Total(props) {
  const classes = useStyles();
  const history = useHistory();

  const API_DATA = "api/totals";
 

  const [data, setData] = React.useState( { 
    'hiking': { 
      'distance': 0,
      'chf': 0
      },
    'biking': {
      'distance': 0,
      'chf': 0
      },
    'jogging': {
        'distance': 0,
        'chf': 0
        }

  });
  
  const getData = () => {
    return fetch(API_DATA, {
        method: 'POST',
        headers: new Headers({
            'Authorization': "Bearer " + localStorage.access_token, 
            'Content-Type': 'application/json'
          })
    })
        .then(res => res.json())
        .then(
            result => {
                console.log("Result received from server: ", result);

                // Check for timeout property --> if yes then session expired
                if (!result.hasOwnProperty("msg")) {

                    // Execute callback function which sets the parameters from the result file
                    if (result !== null) {
                        return result;
                    }
                
                } else {
                    // Session timed out --> Login again
                    history.push("/out/true");
                }
            })
        .catch(err => {
            console.log(err)
        })
};

const setParameters = (result) => {
  let resData = result;
  if (resData !== "nan") {
      setData(resData.res);
  }
};

    // Get values for chart and table data as well as meta data - ONLY ONCE
    React.useEffect( () => {
      getData().then( result => setParameters(result) ) 
  }, []);

 

    return (
      <ThemeProvider theme={props.theme}>
        <React.Fragment>
          <CssBaseline/>
          <main className={classes.layout}>
                < Box my={2} align='center' >
                  < TotalCard typeLogo={<FaHiking />} totalKm={data.hiking.distance} totalCHF={data.hiking.chf} />
                </Box>
                <Box my={2} align='center'>
                  < TotalCard typeLogo={< FaBiking />} totalKm={data.biking.distance} totalCHF={data.biking.chf} />
                </Box>
                <Box my={2} align='center'>
                  < TotalCard typeLogo={< FaRunning />} totalKm={data.jogging.distance} totalCHF={data.jogging.chf} />
                </Box>
            </main>
        </React.Fragment>
      </ThemeProvider>
    );
  }





