import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from '@material-ui/styles';
import {makeStyles} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";

// Card
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// local imports
import NavBar from "./NavBar";
import Copyright from './Copyright';
import cheiraLogo from '../resources/cheira.webp'
import shelterAndWaterLogo from '../resources/shelter&water.png'
import hoerschatzLogo from '../resources/hoerschatz.png'
import velafricaLogo from '../resources/velafrica_logo.png'
// import wsbLogo from '../resources/wsbLogo.png'

import spLogo from '../resources/swiss_prosthetics_logo.png'
// import aphasieLogo from '../resources/aphasie_logo.png'






const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
        align: "center"
      },
      media: {
        height: 140,
      },
    logo: {
        margin: theme.spacing(3),
        // backgroundColor: theme.palette.secondary.main,
    },
  
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        // backgroundColor: "rgba(255, 255, 255, 0.9)",
        backgroundColor: "white",
    },
    media2: {           
        height: 100,     
        width: '33%',
        marginLeft: '33%'
      },

}));


export default function Projects(props) {
    const classes = useStyles();
    const history = useHistory();

    


    return (
        <ThemeProvider theme={props.theme}>
        <React.Fragment>
            <CssBaseline/>
            <NavBar
                // host={props.host}
                currentPage='projects'
            />
            <main className={classes.layout}>
            < Box my={2} >
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                    className={classes.media2}
                    image={hoerschatzLogo}
                    height="10"
                    title="Hoerschatz Image"
                    />
                    <CardContent>
                        <Typography variant="h5" component="div" align='center'>
                            Hörschatz
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="large" color="primary" href='https://hoerschatz.ch'>
                    Mehr erfahren
                    </Button>
                </CardActions>
            </Card>
            </Box>

            < Box my={2} >
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                    className={classes.media}
                    image={velafricaLogo}
                    title="Velafrica Image"
                    />
                    <CardContent>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="large" color="primary" href='https://velafrica.ch '>
                    Mehr erfahren
                    </Button>
                </CardActions>
            </Card>
            </Box>

               

                        <Copyright 
                        style={
                            {
                            flex: 1,
                            justifyContent: 'flex-end',
                            marginBottom: 36
                        }}
                        />
            </main>
        </React.Fragment>
    </ThemeProvider>
    );
};