import * as React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import CssBaseline from "@material-ui/core/CssBaseline";

import Gallery from "react-photo-gallery";

// local imports
import Copyright from './Copyright';
import NavBar from './NavBar'
import LoadingTracker from './LoadingTracker';

import { BiImageAdd } from 'react-icons/bi';

import { trackPromise } from 'react-promise-tracker';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import imageCompression from 'browser-image-compression';
// import { uploadPhoto, getPhotos } from './apiCalls'

const useStyles = makeStyles(theme => ({
  layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
      },
  },
  paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
      },
      background: "white",
  },
}));

export default function Photos(props) {
  const classes = useStyles();
  const history = useHistory();

  const API_UPLOAD = "api/upload";
  const API_PHOTOS = "api/photos/all";

  const [photos, setPhotos] = React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentPhotoData, setcurrentPhotoData] = React.useState("");
  const [compressedFile, setCompressedFile] = React.useState(null);


  React.useEffect( () => {
        // without if statement this gets called infinitely
        if (!photos) {
          console.log('use Effect')
          getPhotos().then( response => setPhotoList(response) ) 
        }
    })


const setPhotoList = (response) => {
  if (response !== "nan") {
    if (!response.hasOwnProperty('error')) {
      setPhotos(response.res.reverse());
        } else {
          setPhotos(response.res)
        }
  }  
};


  
  const handleChange = (image) => {
    console.log(image);
    console.log('originalFile instanceof Blob', image instanceof Blob); // true
    console.log(`originalFile size ${image.size / 1024 / 1024} MB`);
    // trackPromise( upload(image).then( res => setPhotoList(res)) ) 
    trackPromise( upload(image) ) 
  }


  const onPhotoClick = (event, image) => {
    console.log(image);
    setcurrentPhotoData({
      'author': image.photo.author,
      'date': image.photo.date,
  })
    setDialogOpen(true);
}

  const onClick = (event) => {
    console.log(event.target)
    setDialogOpen(false);
  }


  async function getPhotos() {
    try {
        const response = await fetch(API_PHOTOS, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': "Bearer " + localStorage.access_token, 
                    // 'Content-Type': 'application/json'
                  })
            })
        let res = await response.json()
        // console.log(await response.text());
        console.log(res);
        if (!res.hasOwnProperty("msg")) {

          // Execute callback function which sets the parameters from the result file
          if (res !== null) {
              return res;
          }
      
      } else {
          // Session timed out --> Login again
          history.push("/out/true");
          // console.log('should maybe be logged out')
      }
      }
      catch (err) {
        console.log('fetch failed', err);
        // history.push("/out/true");
      }
    }


  async function upload(image) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
      try {
        const compressedFile = await imageCompression(image, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        const formData = new FormData();
        formData.set('file', compressedFile);
        const response = await fetch(API_UPLOAD, {
            method: 'POST',
            headers: new Headers({
            'Authorization': "Bearer " + localStorage.access_token,
            // 'Content-Type': 'multipart/form-data'
            }),
            body: formData
        });
         const res = await response.json();
         console.log(res);
         if (!res.hasOwnProperty("msg")) {

          // Execute callback function which sets the parameters from the result file
          if (res !== null) {
            getPhotos().then( response => setPhotoList(response))
            // setPhotoList(res)
            // return res;
          }
        } else {
          // Session timed out --> Login again
          history.push("/out/true");
        }
    } catch (err) {
        console.log('fetch failed', err);
      }
    }



    return (
      <ThemeProvider theme={props.theme}>
        <React.Fragment>
        <CssBaseline/>
        < NavBar theme={props.theme} 
        // host={props.host} 
        currentPage={'photos'}/>
        <main className={classes.layout}>
        < Box align="center" my={5} >
          <label for="file">
            <div>
                <BiImageAdd size={70} />
            </div>
          </label>
          <input
              style={{ display: "none" }}
              id="file"
              type="file"
              accept="image/*" 
              onChange={(e) => {
                handleChange(e.target.files[0]);
              }
            }
          />
          < LoadingTracker />
        </Box>
        < Box mt={0}>
            {/* {photos? <Gallery photos={photos.reverse()} direction={"column"} onClick={onPhotoClick} /> : ""}    */}
            {photos? <Gallery photos={photos} direction={"column"} onClick={onPhotoClick} /> : ""}   
        </Box>
        <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Info"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {/* Dieses Photo wurde von {currentPhotoData.email} am {currentPhotoData.date} hochgeladen. */}
                          <Typography>Hochgeladen von:</Typography>
                          <Typography style={{fontWeight: "bold"}}> {currentPhotoData.author}</Typography>
                          < br />
                          <Typography>Datum:</Typography>
                          <Typography style={{fontWeight: "bold"}}> {currentPhotoData.date}</Typography>
                          <Typography>---------- ---------- ---------- ----------</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        id="exit"
                        onClick={onClick}
                        color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Copyright 
                        style={
                            {
                            flex: 1,
                            justifyContent: 'flex-end',
                            marginBottom: 36
                        }}
            />
        </main>
        </React.Fragment>
      </ThemeProvider>
    );
  }





