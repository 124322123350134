import '../styles/styles.css';
import { useHistory, useParams} from "react-router-dom";
import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import Copyright from './Copyright';
import sihltalLogo from '../resources/rotary-sihltal.jpeg'

const useStyles = makeStyles(theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        background: "white",
    },
    logo: {
        marginBottom: theme.spacing(3),
    },
    field: {
        margin: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
}));

export default function LoggedOut(props) {

    // Get project id from URL parameter
    let { timeout } = useParams();
    const timeoutParam = (timeout === "true");

    const classes = useStyles();

    const history = useHistory();

    const onClick = (event) => {

        // event.preventDefault();
    
        console.log(event.currentTarget);
        const id = event.currentTarget.id;
    
        switch (id) {
            
            case "login":
                history.push('/login')
                break;
    
            default:
                throw new Error('Unknown button id');
        }
    };

    return (
        <React.Fragment>
            <ThemeProvider theme={props.theme}>
                <main className={classes.layout}>
                    <Paper
                        className={classes.paper}
                        elevation10
                    >
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <img src={sihltalLogo} alt="Rotary"  className={classes.logo} width="70%"/>
                            {/* <img src={logo} className={classes.logo} alt="Rotary Logo" width="30%"/> */}
                        </Box>
                            <Typography 
                                variant="h6"
                                className={classes.title}
                            >
                                {
                                    !timeoutParam ?
                                    "Sie sind derzeit ausgeloggt. Bitte klicken Sie auf den Button, um sich einzuloggen." :
                                    "Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein."
                                }
                            </Typography>

                            <Button
                                id="login"
                                onClick={onClick}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Zum Login
                            </Button>
                    </Paper>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </main>
            </ThemeProvider>
        </React.Fragment>
    );
}