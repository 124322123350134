import '../styles/styles.css';
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@material-ui/styles';


import { useHistory } from "react-router-dom";
import Copyright from './Copyright';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const history = useHistory();
  const classes = useStyles();

  const API_LOGIN_URL = "api/login";


  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);

  const handleChange = (event) => {
      const id = event.target.id;
      console.log("Following ID triggered change handling: " + event.target.id);
      switch (id) {
          case "email":
              setEmail(event.target.value);
              break;
          case "password":
              setPassword(event.target.value);
              break;
          default:
              throw new Error('Unknown id');
      }
  };

  const onClick = (event) => {
      console.log(event.currentTarget);
      const id = event.currentTarget.id;
  
      switch (id) {
          case "confirm":
              loginRequest();
              break;
          default:
              throw new Error('Unknown button id');
      }
  };

  const loginRequest = () => {

      const convertedState = {
          email: email,
          password: password
      };

      // Send HTTP POST request with email and password
      return fetch(API_LOGIN_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(convertedState)
      })
          .then(res => res.json())
          .then(
              result => {
                  console.log("Result received from server: ", result);

                  if (result !== undefined) {

                      if (!result.hasOwnProperty("error")) {
                          // Successful login
                          localStorage.setItem('access_token', result.access_token);
                          localStorage.setItem('refresh_token', result.refresh_token);

                          // Go to home
                          history.push("/");
                          // history.push("/home");
                      } else {
                          //Wrong username or password
                          setWrongCredentials();
                      }
                      
                  }
              },
              (error) => {
                  console.log(error);
              }
          )
  }

  const setWrongCredentials = () => {
      // Set text fields to error
      setEmailError(true);
      setPasswordError(true);
  }

  return (
    <ThemeProvider theme={props.theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          < Box  >
          <TextField
            variant="outlined"
            required
            error={emailError}
            id="email"
            name="email"
            label="E-Mail"
            fullWidth
            value={email}
            onChange={handleChange}
            className={classes.field}
            inputProps={{
                style: { textAlign: "left" }
            }}
          />
          </Box>
          < Box my={1} >
          <TextField
            variant="outlined"
            error={passwordError}
            helperText={passwordError ? "Falsche E-Mail Adresse oder falsches Passwort" : ""}
            id="password"
            name="password"
            label="Password"
            fullWidth
            value={password}
            onChange={handleChange}
            type="password"
            autoComplete="current-password"
            className={classes.field}
            required
            inputProps={{
                style: { textAlign: "left" }
              }}
          />
          </Box>
          <Button
            id="confirm"
            onClick={onClick}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            <Grid item>
              {/* <Link href='/signup' id="signup" variant="body1">
                {"Sie haben noch kein Konto? Sign Up"}
              </Link>   */}
              <Typography onClick={()=>{history.push("/signup")}} id="signup" variant="body1" color="primary" >
                {"Sie haben noch kein Konto? Sign Up"}
              </Typography> 
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </ThemeProvider>
  );
}