import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: "#F7A81B",
//     color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);


// function createData(name, totalKm) {
//   return { name, totalKm};
// }

// const tableList = [
    // {'Torben', '159'}
//   createData('Torben Mannhart', 159),
//   createData('Peter Meier', 237),
//   createData('Anna B.', 262),
//   createData('Cedric T.', 305),
//   createData('Ameira L.', 356),
// ];



const useStyles = makeStyles({
  table: {
    // minWidth: 20,
    minWidth: 260,
    // maxWidth: 300,
  },
});



export default function Leaderbord(props) {
    console.log('starting Leaderbord')
    const history = useHistory();

    const travelType = props.type
    console.log(travelType)
    const classes = useStyles();
    const leaders = props.data

    // const [API_LEADERS, setAPI_LEADERS] = React.useState('')
    // const [APIselected, setAPIselected] = React.useState(false)
    // const [tableList, setTableList] = React.useState([])

    // function formatList(l) {
    //     console.log('formatList', l)
    //     // var formattedList = []
    //     l.forEach(function (user) {
    //         const name = user.name
    //         const total = user.total
    //         setTableList(tableList => [...tableList, {name, total}])
    //         // formattedList.push({name, total})
    //     })
    //     // .then( () => setTableList(formattedList))
    //     // console.log('formatted list', formatList)
    //     console.log('table list after update', tableList)
    // }

    // async function getLeaders(API_LEADERS) {
    //     console.log('starting getLeaders', API_LEADERS)
    //     try {
    //           const response = await fetch(API_LEADERS, {
    //                   method: 'GET',
    //                   headers: new Headers({
    //                       'Authorization': "Bearer " + localStorage.access_token, 
    //                       // 'Content-Type': 'application/json'
    //                     })
    //               })
    //           try {
    //             console.log(response)
    //             let res = await response.json()
    //           // console.log(await response.text());
    //           console.log(res);
    //           if (!res.hasOwnProperty("msg")) {
      
    //             // Execute callback function which sets the parameters from the result file
    //             if (res !== null) {
    //                 console.log('result received from server', res)
    //                 formatList(res.leaders)
                    
    //             }
    //         } else {
    //             // Session timed out --> Login again
    //             history.push("/out/true");
    //             // console.log('should maybe be logged out')
    //         }
    //           }
    //           catch (err) {
    //             console.log('response.json failed')
    //             console.log(err)
    //           }
            
    //       }
    //         catch (err) {
    //           console.log('fetch failed', err);
    //           // history.push("/out/true");
    //       }
    //     }

    // React.useEffect( () => {
    //     console.log('useEffect Leaderboard')
    //     console.log('APIselected', APIselected)
    //     if (!APIselected) {
    //         let APIsting = ''
    //         switch (travelType) {
    //             case "hiking":
    //                 APIsting = 'api/leaders/hiking'
    //                 console.log('api_leaders', APIsting)
    //                 break;
    //             case "biking":
    //                 APIsting = 'api/leaders/biking'
    //                 console.log('api_leaders', APIsting)
    //                 break;
    //             case "jogging":
    //                 APIsting = 'api/leaders/jogging' 
    //                 console.log('api_leaders', APIsting)
    //                 break;
    //             default:
    //                 throw new Error("Unknown travel type");
    //         }
    //         console.log('APIstring after switch', APIsting)
    //         // setAPI_LEADERS(APIsting)
    //         // console.log("API_LEADERS after set",API_LEADERS)
    //         getLeaders(APIsting);
    //         setAPIselected(true) ;
    //     }
    // })

  return (
    <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="customized table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Total&nbsp;(km)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {leaders.map((row) => (
          <TableRow key={row.name}>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.total}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
}