import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from '@material-ui/styles';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";

// icons
import { FaHiking , FaBiking, FaRunning }  from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi';

// Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';




const useStyles = makeStyles(theme => ({
    logo: {
        margin: theme.spacing(3),
        // backgroundColor: theme.palette.secondary.main,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
}));

export default function MyEntries(props) {
    const classes = useStyles();
    const history = useHistory();

    const API_URL = "api/my_entries";
    const API_DELETE_URL = "api/delete/entries";  

    const [entryList, setEntryList] = React.useState();
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState();
    const [currentDeleteEntryId, setCurrentDeleteEntryId] = React.useState();


    const onClick = (event) => {
        const entryId = event.currentTarget.value;
        const buttonId = event.currentTarget.id;
    
        switch (buttonId) {
            case "delete_dialog_no":
                setDeleteDialogOpen(false);
                break;
            case "delete_dialog_yes":
                deleteEntry(currentDeleteEntryId).then( (result) => setParameters(result) ) 
                setDeleteDialogOpen(false);
                break;
            case "delete_project":
                setCurrentDeleteEntryId(entryId);
                setDeleteDialogOpen(true);
                break;
            default:
                throw new Error('Unknown step');      
        }
    };


    const getEntries = () => {
        return fetch(API_URL, {
            method: 'POST',
            headers: new Headers({
                'Authorization': "Bearer " + localStorage.access_token, 
                'Content-Type': 'application/json'
              })
        })
            .then(res => res.json())
            .then(
                result => {
                    console.log("Result received from server: ", result);

                    // Check for timeout property --> if yes then session expired
                    if (!result.hasOwnProperty("msg")) {

                        // Execute callback function which sets the parameters from the result file
                        if (result !== null) {
                            return result;
                        }
                    
                    } else {
                        // Session timed out --> Login again
                        history.push("/out/true");
                    }
                })
            .catch(err => {
                console.log(err)
            })
    };

    const addTypeLogo = (type) => {
        switch (type) {
            case "hiking":
                return <FaHiking />
            case "biking":
                return < FaBiking />
            case "jogging":
                return < FaRunning />
        }

    };

    const setParameters = (result) => {
        let resEntryList = result;

        if (resEntryList !== "nan") {
            const data = resEntryList.res
            const enhanced_data = []
            data.forEach(function (item) {
                let new_dic = item
                let typeLogo = ""
                typeLogo = addTypeLogo(item['type'])
                new_dic["typeLogo"] = typeLogo;
                enhanced_data.push(new_dic);
              });

            setEntryList(enhanced_data);
        }
    };
    
    const deleteEntry = (entryId) => {
        return fetch(API_DELETE_URL + '/' + entryId, {
            method: 'POST',
            headers: new Headers({
                'Authorization': "Bearer " + localStorage.access_token, 
                'Content-Type': 'application/json'
              })
        })
        .then(res => res.json())
        .then(
            result => {
                console.log("Result received from server: ", result);
                // Display remaining Entries
                if (result !== null) {
                    return result
                } else {
                    history.push("/out/true");
                }
                
            }
        )
    };


    // Get values for chart and table data as well as meta data - ONLY ONCE
    React.useEffect( () => {
        console.log(API_URL)
        getEntries().then( result => setParameters(result) ) 
    }, []);

    // const [alignment, setAlignment] = React.useState('left');

    // const handleChange = (event, newAlignment) => {
    //     setAlignment(newAlignment);
    //     console.log(alignment)
    //   };


    return (
        <ThemeProvider theme={props.theme}>
        <React.Fragment>
            <CssBaseline/>
            <main className={classes.layout}>
                < Box my={2}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="right">                </TableCell>
                                <TableCell>                 Datum       </TableCell>
                                <TableCell align="right">   Art         </TableCell>
                                <TableCell align="right">   Distanz (Km) </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {entryList && entryList.map((row) => (
                            <TableRow key={row._id} >
                            <TableCell align="right">
                                    < HiTrash onClick={ () => {
                                        setCurrentDeleteEntryId(row["_id"]);
                                        setDeleteDialogOpen(true);
                                    }} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.date}
                            </TableCell>
                            <TableCell align="right">{row.typeLogo}</TableCell>
                            <TableCell align="right">{row.distance}</TableCell>
                            {/* <TableCell align="right">
                                    < HiTrash onClick={ (event, rowData) => {
                                        setCurrentDeleteEntryId(row["_id"]);
                                        setDeleteDialogOpen(true);
                                    }} />
                            </TableCell> */}
                          </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>                        
                {/* < Box mt={5}> 
                    <Copyright/>
                </Box> */}
            </main>

            <Dialog
                open={deleteDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Eintrag löschen"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sind Sie sicher, dass Sie den Eintrag löschen möchten?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        id="delete_dialog_no"
                        onClick={onClick}
                        color="primary">
                        Nein
                    </Button>
                    <Button
                        id="delete_dialog_yes"
                        onClick={onClick}
                        color="primary"
                        autoFocus>
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    </ThemeProvider>
    );
};