import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from '@material-ui/core/Paper';

import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';

// local imports
import LoadingTracker from '../LoadingTracker';


const useStyles = makeStyles(theme =>({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
},
paper: {
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  marginLeft: "-300px",
  [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
  },
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  backgroundColor: "white",
},
}));







export default React.memo(function AdminTable(props) {
  

    const data = props.data
    const classes = useStyles();
    const columns = props.columns
    const title = props.title

    // const [columns, setColumns] = React.useState(
    //   [
    //     { field: 'name', headerName: 'Name', title: 'Name'},
    //     { field: 'distance', headerName: 'Distanz', title: 'Distanz'},
    //     { field: 'chf', headerName: 'CHF', title: 'CHF'},
    //   ]
    // );

     // , width: 70 can be added to columns
    // const columns = [
    //   { field: 'name', headerName: 'Name', title: 'Name'},
    //   { field: 'date', headerName: 'Datum', title: 'Datum', type: 'date'},
    //   { field: 'type', headerName: 'Art', title: 'Art'},
    //   { field: 'distance', headerName: 'Distanz', title: 'Distanz'},
    //   { field: 'chf', headerName: 'CHF', title: 'CHF'},
    // ]



    return (
      <ThemeProvider theme={props.theme}>
        { data ? 
      <React.Fragment>
          {/* <CssBaseline/> */}
          {/* < main className={classes.layout}> */}
          < main >
              <Paper
                  className={classes.paper}
                  elevation10
              >
                < Box my={2}>
                  <Typography component="h1" variant="h4" align="center" className={classes.title}>
                      {title}
                  </Typography>

                  </Box>
                  
                  <MaterialTable
                      title=""
                      columns={columns}
                      data={data}
                      // actions={[
                      //     {
                      //         icon: 'launch',
                      //         tooltip: 'Öffnen',
                      //         onClick: (event, rowData) => {
                      //             history.push('/result/' + rowData["_id"]);
                      //         }
                      //     },
                      //   ]}
                        options={{
                          search: true,
                          headerStyle: {
                              fontWeight: "bold",
                          }
                        }}
                        style={{
                            boxShadow: "0px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            // minWidth: "500px",
                        }}
                      />

              </Paper>
              </main>
      </React.Fragment>
// wait for data loading 
  : (
    < LoadingTracker /> )
  }
    </ThemeProvider> 
    );
    });