import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './App.css';
import Home from "./components/Home"
import SignIn from "./components/SignIn"
import SignUp from "./components/SignUp"
import LoggedOut from "./components/LoggedOut"
import MyData from "./components/MyData"
import Dashboard from "./components/Dashboard"
import Photos from "./components/Photos"
import Projects from './components/Projects';
import Admin from './components/Admin';

import { createMuiTheme } from '@material-ui/core/styles';
// import { createTheme } from '@material-ui/core/styles'
// import { deDE } from '@material-ui/core/locale';


const theme = createMuiTheme({
    palette: {
    //   type: 'dark',
      primary: {
        main: '#F7A81B',
      },
      secondary: {
        main: '#b3760e',
      },
    },
  });


 
// Define host domain
// const host = "http://localhost:5000/api/"
// const host = "https://www.soltar-tco.biz/api/api/"


export default function App() {

    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home theme={theme} />
                </Route>
                <Route exact path="/home">
                    <Home theme={theme} />
                </Route>
                <Route exact path="/login">
                    <SignIn theme={theme} />
                </Route>
                <Route path="/signup">
                    <SignUp theme={theme} />
                </Route>
                <Route path="/data">
                    < MyData theme={theme} />
                </Route>
                <Route path="/dashboard">
                    < Dashboard theme={theme} />
                </Route>
                <Route path="/photos">
                    < Photos theme={theme} />
                </Route>
                <Route path="/projects">
                    < Projects theme={theme} />
                </Route>

                <Route path="/admin">
                    < Admin theme={theme} />
                </Route>

                <Route exact path="/out/:timeout">
                    <LoggedOut theme={theme} />
                </Route>
                <Route path="/*">
                    <Home theme={theme}/>
                </Route>
            </Switch>
        </Router>
    );
}

