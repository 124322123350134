import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';


export default function LoadingTracker(props) {
    const { promiseInProgress } = usePromiseTracker();
    
       return (
        <React.Fragment> 
        {
        promiseInProgress && 
         <div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            >
            <Loader type="ThreeDots" color="#F7A81B" height="100" width="100" />
        </div>
        }
        </React.Fragment>
      );  
     }

 


