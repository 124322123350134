import * as React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Gallery from "react-photo-gallery";
import LoadingTracker from './LoadingTracker';
import { BiImageAdd } from 'react-icons/bi';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { trackPromise } from 'react-promise-tracker';
import imageCompression from 'browser-image-compression';


export default function Photos(props) {
  const history = useHistory();

  const API_UPLOAD = "api/upload";
  const API_PHOTOS = "api/photos/my";
  const API_DELETE_URL = "api/delete/photos";  

  const [photos, setPhotos] = React.useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState();
  const [currentDeleteEntryId, setCurrentDeleteEntryId] = React.useState();

  React.useEffect( () => {
        // without if statement this gets called infinitely
        if (!photos) {
          console.log('use Effect')
          getPhotos().then( response => setPhotoList(response) ) 
        }
    })


const setPhotoList = (response) => {
    if (response !== "nan") {
      if (!response.hasOwnProperty('error')) {
        setPhotos(response.res.reverse());
          } else {
            setPhotos(response.res)
          }
    }  
};


const handleChange = (image) => {
  console.log(image);
  console.log('originalFile instanceof Blob', image instanceof Blob); // true
  console.log(`originalFile size ${image.size / 1024 / 1024} MB`);
  // trackPromise( upload(image).then( res => setPhotoList(res)) ) 
  trackPromise( upload(image) ) 
}
//   , image
  const onClick = (event) => {
    console.log(event.currentTarget.id)
    const fileId = event.currentTarget
    const buttonId = event.currentTarget.id;    
    
    // const fileId = image.photo.file_id
    // const buttonId = event.currentTarget.id;
    // const buttonId = image.id

    switch (buttonId) {
        case "delete_dialog_no":
            setDeleteDialogOpen(false);
            break;
        case "delete_dialog_yes":
            deletePhoto(currentDeleteEntryId)
            setDeleteDialogOpen(false);
            break;
        // case "delete_photo":
        //     setCurrentDeleteEntryId(fileId);
        //     setDeleteDialogOpen(true);
        //     break;
        default:
            throw new Error('Unknown step');      
    }
};

const onPhotoClick = (event, image) => {
    const fileId = image.photo.file_id
    setCurrentDeleteEntryId(fileId);
    setDeleteDialogOpen(true);
}

const deletePhoto = (fileId) => {
    return fetch(API_DELETE_URL + '/' + fileId, {
        method: 'POST',
        headers: new Headers({
            'Authorization': "Bearer " + localStorage.access_token, 
            'Content-Type': 'application/json'
          })
    })
    .then(res => res.json())
    .then(
        result => {
            console.log("Result received from server: ", result);
            // Display remaining Entries
            if (result !== null) {
                setPhotoList(result)
                return result
            } else {
                history.push("/out/true");
            }
            
        }
    )
};

async function getPhotos() {
        try {
            const response = await fetch(API_PHOTOS, {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': "Bearer " + localStorage.access_token, 
                        // 'Content-Type': 'application/json'
                      })
                })
            let res = await response.json()
            // console.log(await response.text());
            console.log(res);
            if (!res.hasOwnProperty("msg")) {

              // Execute callback function which sets the parameters from the result file
              if (res !== null) {
                  return res;
              }
          
          } else {
              // Session timed out --> Login again
              history.push("/out/true");
              // console.log('should maybe be logged out')
          }
          }
          catch (err) {
            console.log('fetch failed', err);
            history.push("/out/true");
          }
        }


  async function upload(image) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
      try {
        const compressedFile = await imageCompression(image, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        const formData = new FormData();
        formData.set('file', compressedFile);
        const response = await fetch(API_UPLOAD, {
            method: 'POST',
            headers: new Headers({
            'Authorization': "Bearer " + localStorage.access_token,
            // 'Content-Type': 'multipart/form-data'
            }),
            body: formData
        });
         const res = await response.json();
         console.log(res);
         if (!res.hasOwnProperty("msg")) {
          
          // Execute callback function which sets the parameters from the result file
          if (res !== null) {
            getPhotos().then( response => setPhotoList(response))
            // setPhotoList(res)
            // return res;
          }
        } else {
          // Session timed out --> Login again
          history.push("/out/true");
        }
    } catch (err) {
        console.log('fetch failed', err);
      }
    }



    return (
      <ThemeProvider theme={props.theme}>
        <React.Fragment>
        < Box align="center" my={5} >
          <label for="file">
            <div>
                <BiImageAdd size={70} />
            </div>
          </label>
          <input
              style={{ display: "none" }}
              id="file"
              type="file"
              accept="image/*" 
              onChange={(e) => {
                handleChange(e.target.files[0]);
              }
            }
          />
          < LoadingTracker />
        </Box>
        < Box mt={0}>
            {photos? <Gallery photos={photos} direction={"column"} onClick={onPhotoClick} id="delete_photo" /> : ""}   
        </Box>
        <Dialog
                open={deleteDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Photo löschen"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sind Sie sicher, dass Sie das Photo löschen möchten?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        id="delete_dialog_no"
                        onClick={onClick}
                        color="primary">
                        Nein
                    </Button>
                    <Button
                        id="delete_dialog_yes"
                        onClick={onClick}
                        color="primary"
                        autoFocus>
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
      </ThemeProvider>
    );
  }





