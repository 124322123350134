import '../styles/styles.css';
import { useHistory } from "react-router-dom";
// react imports
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from '@material-ui/core/Paper';

// input field
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';

// date picker
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import deLocale from "date-fns/locale/de";

//success toast
import MuiAlert from '@material-ui/lab/Alert';

// local imports
import Copyright from './Copyright';
import NavBar from './NavBar'
// import SignIn from './SignIn';
import {getCurrentDate} from './utils'

// import logo from '../resources/rotary-logo-yellow.png';
// import sihltalLogo from '../resources/rotary-sihltal-screenshot.png'
import sihltalLogo from '../resources/rotary-sihltal.jpeg'

// icons
import { FaHiking , FaBiking, FaRunning }  from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
    navTitle: {
        flexGrow: 1,
        marginLeft: 20,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
    },
    logo: {
        marginBottom: theme.spacing(8),
        // height: "100px",
        // widht: "100px",
        height: "1%",
        // widht: "100px",


        // max-width: 100%;
    },

    submit: {
        margin: theme.spacing(0),
        color: theme.palette.common.black,
    },
    title: {
        marginBottom: theme.spacing(3)
    },
    subTitle: {
        marginBottom: theme.spacing(8)
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: '25ch',
      },
}));

export default function Home(props) {
    const classes = useStyles();
    const history = useHistory();

    // console.log(getCurrentDate())

    const API_URL_LOGOUT = "api/logout";
    const API_URL_NEW_ENTRY = "api/new_entry";
    const API_TODAY = "api/current_date"


    // const today = new Date(getCurrentDate()).toISOString().split('T')[0]
    
  
    const todayPlaceHolder = new Date("2021-07-01")


    const [today, setToday] = React.useState("");
    const [selectedDate, setSelectedDate] = React.useState(todayPlaceHolder)
    const [todayFetched, setTodayFetched] = React.useState(false)

    React.useEffect( () => {
        // without if statement this gets called infinitely
        if (!todayFetched) {
          console.log('use Effect')
          getCurrentDate().then( response => setToday(response?.date) )
          .then(() => setSelectedDate(today))
          .then( () => setTodayFetched(true))
        }
    })



    const [distance, setDistance] = React.useState("");
    const [distanceValueError, setDistanceValueError] = React.useState(false)

    const [successfulEntry, setSuccessfulEntry] = React.useState(false)

    const [hikingSelected, setHikeingSelected] = React.useState(false);
    const [bikingSelected, setBikingSelected] = React.useState(true);
    const [joggingSelected, setJoggingSelected] = React.useState(false);
    const [travelType, setTravelType] = React.useState('biking')

    const onClick = (event) => {
        console.log(event.currentTarget);
        const id = event.currentTarget.id;
        switch (id) {
            case 'hiking':
                setHikeingSelected(true);
                setBikingSelected(false);
                setJoggingSelected(false);
                setTravelType('hiking');
                setSuccessfulEntry(false)
                break;
            case 'biking':
                setHikeingSelected(false);
                setBikingSelected(true);
                setJoggingSelected(false);
                setTravelType('biking');
                setSuccessfulEntry(false)
                break;

            case 'jogging':
                setHikeingSelected(false);
                setBikingSelected(false);
                setJoggingSelected(true);
                setTravelType('jogging');
                setSuccessfulEntry(false)
                break;

            case 'submit':
                newEntryRequest()
                break;

            case "logout":
                // Send HTTP POST request to delete refresh token
                fetch(API_URL_LOGOUT, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Authorization': "Bearer " + localStorage.refresh_token, 
                        'Content-Type': 'application/json'
                    }), 
                })
                    .then(res => res.json())
                    .then(
                        result => {
                            localStorage.removeItem('access_token');
                            localStorage.removeItem('refresh_token');
                            history.push('/login')

                            console.log("Refresh token deleted")
                        },
                        (error) => {
                            console.log(error);
                        }
                    )
                break;
            default:
                throw new Error('Unknown button id');
        }
    };

    const newEntryRequest = () => {
        console.log(selectedDate)

        const convertedState = {
            type: travelType,
            distance: distance,
            date: selectedDate,
        };

        if (!convertedState.distance == "") {
  
        // Send HTTP POST request with email and password
        return fetch(API_URL_NEW_ENTRY, {
            method: 'POST',
            headers: new Headers({
                'Authorization': "Bearer " + localStorage.access_token, 
                'Content-Type': 'application/json'
              }), 
            body: JSON.stringify(convertedState)
        })
            .then(res => res.json())
            .then(
                result => {
                    console.log("Result received from server: ", result);
  
                    if (result !== undefined) {
  
                        if (result.hasOwnProperty("message")) {
                            // Successful POST REQUEST
                            setSuccessfulEntry(true);
                            setDistance('');
                            

                        } else {
                            console.log('new entry failed')
                            history.push("/out/true");
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    } else {
        console.log("distance must be filled")
    }
}

    const handleChange = (event) => {
        if(!isNaN(event.target.value)) {
            setDistance(event.target.value);
            setSuccessfulEntry(false)
        } 
        
      };

      const handleDateChange = (date) => {
        setSuccessfulEntry(false)
        date = date.toISOString().split('T')[0]
        setSelectedDate(date);
        // console.log(selectedDate)
      };

      async function getCurrentDate() {
        try {
            const response = await fetch(API_TODAY, {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': "Bearer " + localStorage.access_token, 
                        // 'Content-Type': 'application/json'
                      })
                })
            let res = await response.json()
            // console.log(await response.text());
            // console.log(res);
            if (!res.hasOwnProperty("msg")) {
    
              // Execute callback function which sets the parameters from the result file
              if (res !== null) {
                  console.log(res)
                  return res;
              }
          
          } else {
              // Session timed out --> Login again
              history.push("/out/true");
              // console.log('should maybe be logged out')
          }
          }
          catch (err) {
            console.log('fetch failed', err);
            // history.push("/out/true");
          }
        }




      function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    return (
        <ThemeProvider theme={props.theme}>
        {localStorage.access_token ? 
        <React.Fragment>
            <CssBaseline/>
            < NavBar theme={props.theme} 
                    // host={props.host} 
                    // currentPage={'home'} 
            />
            <main className={classes.layout}>
            
            < Box my={1} mx={1}>
            <Paper  
                    elevation10
            >
            <link href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900|Roboto:300,400,500,700,900&display=swap" rel="stylesheet"/>
                {/* <Container class="home" style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: "space-between",
                }} >
                    <Container 
                        maxWidth="md"
                    > */}
                        
                        <div className={classes.paper}>
                        <div className={classes.logo}>
                        <Box mt={2} mb={-10} >
                        {/* <img src={logo} alt="Rotary" height={100} className={classes.logo}/> */}
                        <img src={sihltalLogo} alt="Rotary"  className={classes.logo} width="300px"/>
                        </Box>
                        </div>
                            <Grid   container  
                                    justify="center"
                                    alignItems="center"
                                    textAlign="center"
                            >
                                <Box >
                                <Grid  > 
                                {/* item xs={hikingSelected ? 4 : 1} px={4} */}
                                    <Button
                                        id="hiking"
                                        onClick={onClick}
                                        fullWidth
                                        variant="contained"
                                        color={hikingSelected ? "primary" : "inherit"}
                                        className={classes.submit}
                                        startIcon={<FaHiking />}
                                    >
                                        {hikingSelected ? "Wandern" : ""}
                                    </Button>
                                </Grid>
                                </Box>
                                <Box mx={1} >
                                <Grid>    
                                    <Button
                                        id="biking"
                                        onClick={onClick}
                                        fullWidth
                                        variant="contained"
                                        color={bikingSelected ? "primary" : "inherit"}
                                        className={classes.submit}
                                        startIcon={<FaBiking />}
                                    >
                                        {bikingSelected ? "Fahrrad" : ""}
                                    </Button>
                                </Grid>
                                </Box>
                                <Box >
                                <Grid>
                                    <Button
                                        id="jogging"
                                        onClick={onClick}
                                        fullWidth
                                        variant="contained"
                                        color={joggingSelected ? "primary" : "inherit"}
                                        backgroundColor={joggingSelected ? "primary" : "inherit"}
                                        className={classes.submit}
                                        startIcon={<FaRunning />}
                                    >
                                        {joggingSelected ? "Joggen" : ""}
                                    </Button>
                                </Grid>
                                </Box>
                            </Grid>
                            <Box mt={5}>
                                <OutlinedInput
                                    error={distanceValueError}
                                    helperText={distanceValueError ? "Bitte nur Zahlen verwenden. Bsp. 11.4" : ""}
                                    id="distance"
                                    color="inherit"
                                    value={distance}
                                    onChange={handleChange}
                                    endAdornment={<InputAdornment position="end">Km</InputAdornment>}
                                    placeholder="z.B. 10.2"
                                    inputProps={{
                                    'aria-label': 'Distanz',
                                    }}
                                    
                                />  
                            </Box>
                            <Box >
                                {/* < DatePicker /> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Datum"
                                        format="dd/MM/yyyy"
                                        // format="dd.MM.yyyy"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Box>

                            <Box mt={2}>
                                <Grid>
                                    <Button
                                        id="submit"
                                        onClick={onClick}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        backgroundColor="primary"
                                        className={classes.submit}
                                    >
                                        Absenden
                                    </Button>
                                </Grid>
                                </Box>
                                < Box mt={2}>
                                {successfulEntry ? <Alert severity="success">neuer Eintrag wurde registriert.</Alert> : ""}
                                </Box>
                        </div>
                      
                    {/* </Container> */}

                    </Paper>
                    </Box>
                

                                
                    {/* <Box mt={8}>
                        <Copyright />
                    </Box> */}
                   
                        <Copyright 
                        style={
                            {
                            flex: 1,
                            justifyContent: 'flex-end',
                            marginBottom: 36
                        }}
                        />
             
                    
                {/* </Container> */}
                </main>
        </React.Fragment> 
        : history.push('/login')}

        {/* // : <SignIn  */}
        {/* // // host={props.host} 
        // theme={props.theme} />} */}
    </ThemeProvider>
    );
}
